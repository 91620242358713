import * as React from "react";

import Bell from "@/components/icons/bell.svg";
import CheckCirle from "@/components/icons/check-circle.svg";
import CloudIcon from "@/components/icons/cloud-icon.svg";
import Cog from "@/components/icons/cog.svg";

const Features: React.VoidFunctionComponent = () => {
  return (
    <div className="mx-auto max-w-7xl py-16 px-8">
      <h2 className="heading gradient-color">Features</h2>
      <p className="subheading">Simplify operations to maximize your revenue</p>
      <div className="grid grid-cols-2 gap-12">
        <div className="col-span-2 md:col-span-1">
          <div className="mb-4 inline-block rounded-2xl bg-green-100/50 p-3 text-green-400">
            <div className="flex h-8 w-8 items-center justify-center">
              <CloudIcon />
            </div>
          </div>
          <h3 className="heading-sm flex items-center">Cloud Based</h3>
          <p className="text">
            Being cloud based, you can access the PropsAtlas system from
            anywhere where a basic internet connection is available.
          </p>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="mb-4 inline-block rounded-2xl bg-cyan-100/50 p-3 text-cyan-400">
            <CheckCirle className="h-8 w-8" />
          </div>
          <h3 className="heading-sm">Easy To Use</h3>
          <p className="text">
            A user-friendly interface ensures that you can easily view and
            manage inventory, and change, move and edit bookings on PC or mobile
            devices.
          </p>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="mb-4 inline-block rounded-2xl bg-rose-100/50 p-3 text-rose-400">
            <Bell className="h-8 w-8" />
          </div>
          <h3 className="heading-sm">Guest Relationship</h3>
          <p className="text">Management of retail and group guests.</p>
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="mb-4 inline-block rounded-2xl bg-yellow-100/50 p-3 text-yellow-400">
            <Cog className="h-8 w-8" />
          </div>
          <h3 className="heading-sm">Member Roles</h3>
          <p className="text">
            Delegating and granting permissions to group members
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
