import Head from "next/head";
import React from "react";

import { siteMetadata } from "@/data/site-metadata";

import Features from "./home/features";
import { FreeSignup } from "./home/free-signup";
import Hero from "./home/hero";
import PageLayout from "./page-layout";

const Home: React.VoidFunctionComponent = () => {
  return (
    <PageLayout>
      <Head>
        <meta name="description" content={siteMetadata.description} />
        <meta
          name="keywords"
          content="PropsAtlas, Rental Management, Hotel, Booking, Reservation"
        />
        <title>{siteMetadata.title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={siteMetadata.title}></meta>
        <meta
          property="og:description"
          content={siteMetadata.description}
        ></meta>
        <meta property="og:image" content={siteMetadata.ogImage}></meta>
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:title" content={siteMetadata.title}></meta>
        <meta
          property="twitter:description"
          content={siteMetadata.description}
        ></meta>
        <meta property="twitter:image" content={siteMetadata.ogImage}></meta>
        <link rel="canonical" href="https://propsatlas.com/"></link>
      </Head>
      <Hero />
      <Features />
      <FreeSignup />
    </PageLayout>
  );
};

export default Home;
