import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import * as React from "react";

import HeroBanner from "@/components/icons/hero-banner.svg";

const Hero: React.VoidFunctionComponent = () => {
  const { t } = useTranslation("homepage");

  return (
    <div className="mx-auto max-w-7xl items-end p-2 lg:flex lg:justify-between">
      <div className="my-8 text-center lg:text-left">
        <h1 className="text-4xl sm:text-5xl">
          <span className="gradient-color text-xl">PropsAtlas</span>
          <br />
          <span className="font-bold text-gray-900 ">
            Rental Property Management
          </span>
          <br />
        </h1>
        <div className="mb-12 text-xl text-gray-400">
          Easily manage your properties and have more time to satisfy your
          guests
        </div>
        <div className="lg:space-x-3">
          <a
            target="_blank"
            aria-label="Sign up PropsAtlas"
            href="https://app.propsatlas.com/register"
            className="gradient-bg rounded-lg px-5 py-3 font-semibold text-white shadow-sm  transition-all hover:bg-orange-500/90 hover:text-white hover:no-underline hover:shadow-md focus:ring-2  focus:ring-orange-200 active:bg-orange-600/90"
            rel="noreferrer"
          >
            {t("startFreeBtn", "Sign up for free")}
          </a>
        </div>
      </div>
      <div className="pointer-events-none mt-16 hidden h-[380px] select-none items-end justify-center md:flex lg:mt-0 lg:ml-24">
        <div className="relative inline-block">
          {/* <motion.div
            className="absolute z-20 rounded-full bg-orange-500 py-1 px-3 text-sm text-slate-100"
            initial={{
              opacity: 0,
              right: 390,
              top: -65,
              translateY: 50,
            }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ type: "spring", delay: 2 }}
          >
            Perfect! 🤩
            <ScribbleArrow className="absolute -right-8 top-3 text-slate-400" />
          </motion.div> */}
          <motion.div
            className="rounded-lg shadow-lg"
            transition={{ type: "spring", delay: 0.5 }}
            initial={{ opacity: 0, translateY: -100 }}
            animate={{ opacity: 1, translateY: 0 }}
          >
            <HeroBanner />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
