import Logo from "../../public/propsatlas.svg";

export const FreeSignup: React.FC = () => {
  return (
    <div className="flex flex-col items-center gap-2">
      <Logo className="h-20 w-20" />
      <h2 className="text-4xl">Try PropsAtlas today</h2>
      <p>Get started for free</p>
      <a
        className="gradient-bg rounded-xl px-5 py-2 font-semibold text-white shadow-sm  transition-all hover:cursor-pointer hover:bg-orange-500/90 hover:text-white hover:no-underline hover:shadow-md focus:ring-2  focus:ring-orange-200 active:bg-orange-600/90"
        href="https://app.propsatlas.com/register"
        target="_blank"
        rel="noreferrer"
      >
        Try PropsAtlas Free
      </a>
    </div>
  );
};

export default FreeSignup;
