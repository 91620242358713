import { useTranslation } from "next-i18next";
import * as React from "react";

import Logo from "../../public/propsatlas-gray.svg";

const Footer: React.VoidFunctionComponent = () => {
  const { t } = useTranslation("homepage");
  return (
    <div className="bg-pattern mt-16 bg-slate-50/70">
      <div className="mx-auto flex max-w-7xl justify-between gap-8 py-20 px-8">
        <div className="col-span-12 md:col-span-12">
          <Logo className="mb-4 w-40 text-gray-400" />
          <p className="text-sm text-gray-400">
            {t("title", "Rental Property Management")}
          </p>
          <div className="flex space-x-3"></div>
        </div>

        <div className="col-span-12 md:col-span-4">
          <p className="inline-block text-white">
            <span className="mb-1 inline-block w-full text-right text-xs italic text-gray-400">
              ©2022 propsatlas.com
            </span>
            <span className="mb-1 inline-block w-full text-right text-xs italic text-gray-400">
              All Rights Reserved
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
