export const siteMetadata = {
  title: "PropsAtlas - Rental Property Management",
  author: "PropsAtlas",
  headerTitle: "PropsAtlas's Portfolio",
  description:
    "Easily manage your properties and have more time to satisfy your guests",
  language: "en-us",
  siteUrl: "https://propsatlas.com",
  ogImage: "https://propsatlas.com/poster.jpg",
  avatarImage:
    "https://res.cloudinary.com/osbkca/image/upload/v1648375238/notionable/avatar_ykoz48.png",
  socialBanner: "",
  email: "mailto:help.xclippy@gmail.com",
  github: "https://github.com/doankhoi",
  twitter: "https://twitter.com/osbkca",
  twitterHandle: "@osbkca",
  codepen: "",
  linkedin: "https://www.linkedin.com/in/doan-ngoc-khoi/",
  newsletter: "",
  resume: "",
  snippets: "",
  carbonCode: "",
  carbonPlacement: "",
  locale: "en-US",
};
